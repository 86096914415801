import { render, staticRenderFns } from "./BuyerEnquiry.vue?vue&type=template&id=4aa44ae4&scoped=true&"
import script from "./BuyerEnquiry.vue?vue&type=script&lang=ts&"
export * from "./BuyerEnquiry.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4aa44ae4",
  null
  
)

export default component.exports